<template>
    <div style="flex: 1">
        <div class="bg" v-if="showDataSheet">
            <data-sheet :id="selectedId" style="margin: 0 auto" @close="showDataSheet = false"></data-sheet>
        </div>
        <div class="page-top">
            <div class="page-top-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="font-size: 12px; color: #c1c6c8" :to="{ path: '/' }">首页
                    </el-breadcrumb-item>
                    <el-breadcrumb-item style="font-size: 12px; color: #c1c6c8" :to="{ path: '/testlist' }">测试列表
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page-top-right">
                你好 , {{ $store.state.username }}
                <span @click="signOut" class="spn">退出登录</span>
            </div>
        </div>

        <div class="text" v-if="!isif">
            <div class="list">
                <div class="left">
                    <p>测试列表</p>
                </div>
            </div>
            <div class="right">
                <button class="add" @click="addbutto()">添加测试</button>
            </div>
            <div class="table-a">
                <el-row style="line-height: 40px">
                    <el-col :span="1">
                        <div class="grid-content bg-purple">
                            <p class="p">#</p>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <div class="grid-content bg-purple">
                            <p class="p">所属产品</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            <p class="p">版本</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            <p class="p">状态</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            <p class="p">创建时间</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <p class="p">操作</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-for="item in tableObj.list" :key="item.id">
                    <el-col :span="1">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit">{{ item.id }}</p>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit">{{ item.product_name }}</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit">{{ item.version_name }}</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit" @click="clickProgress(item.id)">
                                <b-progress :max="100">
                                    <b-progress-bar :value="item.progress" animated><span>{{ item.progress }}%</span>
                                    </b-progress-bar>
                                </b-progress>
                            </p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit">{{ handleDate(item.created_time) }}</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple-buttom">
                            <p class="ptit">
                                <template v-if="item.progress == 0">
                                    <a href="" @click="startTest(item.id)" class="labels">开始测试</a>
                                </template>
                                <a target="_black" :href="item.report_policy" v-if="item.report_policy"
                                    class="labels">安全策略</a>
                                <a target="_black" :href="item.report_known" v-if="item.report_known"
                                    class="labels">已知风险</a>
                                <a target="_black" :href="item.report_unknown" v-if="item.report_unknown"
                                    class="labels">未知风险</a>
                                <a target="_black" :href="item.report_detail" v-if="item.report_detail"
                                    class="labels">分析汇总</a>
                            </p>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="label">
                <ul class="pager">
                    <li class="page-item-a" @click="prevousPage"><span>上一页</span></li>
                    <li class="page-item-a" @click="nextPage">
                        <span>下一页</span>
                    </li>
                    <li class="page-item">
                        <span>当前第{{ page }}页/共{{ tableObj.total_page }}页</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="newadd1" v-show="isif">
            <div class="list3">
                <div class="left3">
                    <p class="addp">添加测试</p>
                </div>
            </div>
            <div class="test">
                <div class="test-left">
                    <div class="text-left-top1">
                        <span class="test-left-title">
                            所属产品
                            <span class="test-left-require">*</span>
                        </span>
                        <select name="" v-model="product_id" class="test-input" style="height: 36px">
                            <option v-for="item in listAll" :key="item.id" :value="item.id">
                                {{ item.product_name }}
                            </option>
                        </select>
                    </div>
                    <br />
                    <div class="test-left-top2">
                        <span class="test-left-title">版本 <span class="test-left-require">*</span></span>
                        <input v-model="version_name" type="text" class="test-input" />
                    </div>
                    <br />
                    <div class="test-left-top3">
                        <span class="test-left-title">日期 </span>
                        <input v-model="version_date" type="text" class="test-input" />
                    </div>
                    <br />
                    <div class="test-left-top4">
                        <span class="test-left-title">说明 </span>
                        <input v-model="description" type="text" class="test-input" />
                    </div>
                    <br />
                    <div class="test-left-top5" style="display: flex; align-items: center">
                        <span class="test-left-title"><label for="file">选择文件 <span
                                    class="test-left-require">*</span></label></span>
                        <input id="file" @change="handleFile" type="file" class="test-file" />
                    </div>
                    <div class="fff" />
                    <div class="submit">
                        <button class="anniu" @click="sub()">提交</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import DataSheet from "@/components/DataSheet.vue";
export default {
    components: { DataSheet },
    data() {
        return {
            isif: false,
            product_id: 123,
            version_name: "",
            version_date: "",
            description: "",
            version_file: "",
            page: this.$route.query.page ? this.$route.query.page : 1,
            size: 20,
            tableObj: {},
            listAll: [],
            showDataSheet: false,
            selectedId: 0,
        };
    },
    watch: {
        isif(val) {
            if (val) return this.getSelectList();
        },
    },
    methods: {
        clickProgress(id) {
            this.showDataSheet = true;
            this.selectedId = id;
            console.log(id);
        },
        handleDate(val) {
            return dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        nextPage() {
            if (this.page <= 1 || this.page >= this.tableObj.total_page) {
                console.log("no page");
                return;
            }
            console.log(parseInt(this.page) + 1);
            this.$router.push({
                path: "/testlist",
                query: {
                    page: parseInt(this.page) + 1,
                },
            });
            location.reload();
        },
        prevousPage() {
            if (this.page <= 1 || this.page >= this.tableObj.total_page) {
                console.log("no page");
                return;
            }
            this.$router.push({
                path: "/testlist",
                query: {
                    page: parseInt(this.page) - 1,
                },
            });
            location.reload();
        },
        addbutto() {
            this.isif = true;
        },
        sub() {
            const {
                product_id,
                version_name,
                version_date,
                description,
                version_file,
            } = this;
            const formData = new FormData();
            formData.append("product_id", product_id);
            formData.append("version_name", version_name);
            formData.append("version_date", version_date);
            formData.append("description", description);
            formData.append("version_file", version_file);
            this.$axios({
                method: "POST",
                url: "version/create",
                headers: {
                    "content-type": "multipart/form-data",
                },
                data: formData,
            })
                .then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch((err) => { });
        },
        handleFile(e) {
            this.version_file = e.target.files[0];
        },
        //获取列表
        getLists() {
            console.log("#", this.page);
            return this.$axios
                .post(`version/list`, {
                    page: this.page,
                    size: this.size,
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        this.tableObj = res.data.data;
                        console.log("@", res.data);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        //获取列表状态
        getListsStatus() {
            const ids = this.tableObj.list.map((item) => item.id);
            return this.$axios
                .post(`version/status`, { version_ids: ids.join(",") })
                .then((res) => {
                    if (res.data.code == 0) {
                        console.log(res.data.data);
                        this.tableObj.list = this.tableObj.list.map((item, index) => {
                            return { ...item, ...res.data.data[index] };
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        startTest(id) {
            this.$axios.post(`version/start`, { id }).then(async (res) => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    await this.getLists();
                    await this.getListsStatus();
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        signOut() {
            this.$confirm("确定要退出吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    window.sessionStorage.clear();
                    this.$store.commit("SET_TOKEN", "");
                    this.$store.commit("SET_USER_NAME", "");
                    this.$message.success("退出成功");
                    setTimeout(() => {
                        this.$router.push({
                            name: "login",
                            replace: true,
                        });
                    }, 1500);
                })
                .catch((err) => { });
        },
        //获取下拉列表
        getSelectList() {
            this.$axios.post(`product/listall`).then((res) => {
                if (res.data.code == 0) {
                    this.listAll = res.data.data.list;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
    },
    async created() {
        await this.getLists();
        await this.getListsStatus();
        // this.getSelectList();
    },
};
</script>
<style scoped>
* {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    list-style: none;
}

.el-tree-node.is-expanded>.el-tree-node__children {
    display: inline;
}

.page-top {
    flex: 1;
    height: 50px;
    /* background: pink; */
    /* border: 1px solid #6c757d; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.bg {
    z-index: 101;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.page-top-right {
    color: #ccc;
}

.spn {
    display: inline-block;
    width: 64px;
    height: 20px;
    /* background: cyan; */
    color: blue;
}

.text {
    height: auto;
    /* background: red; */
    position: relative;
    /* top: 60px; */
}

.el-col {
    border-radius: 4px;
}

.grid-content {
    min-height: 36px;
}

.p {
    font-size: 12px;
    color: #888888;
    margin-left: 10px;
    font-weight: 700;
}

.bg-purple {
    background: #ffffff;
    /* border: 1px solid #ccc; */
    line-height: 40px;
    text-align: left;
    border-bottom: 2px solid currentColor;
}

.bg-purple-buttom {
    border: 1px solid #ccc;
    line-height: 48px;
    text-align: left;
    height: 48px;
    border-left: none;
    border-right: none;
}

.ptit {
    font-size: 12px;
    color: #888888;
    margin-left: 10px;
    font-weight: 700;
}

.label {
    width: 100%;
    height: 54px;
    /* background: red; */
    margin-top: 20px;
}

.pager {
    display: flex;
    justify-content: center;
    line-height: 54px;
}

.page-item-a {
    width: 74px;
    height: 38px;
    border: 1px solid #ccc;
    line-height: 38px;
    color: #909399;
}

.page-item {
    width: 148px;
    height: 38px;
    border: 1px solid #ccc;
    line-height: 38px;
    color: #ccc;
}

.list {
    width: 100%;
    height: 60px;
    background: #f8f9fa;
    display: flex;
    justify-content: space-between;
}

.left {
    width: 80px;
    height: 60px;
    /* background: cyan; */
    margin-left: 24px;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
}

.right {
    width: 92px;
    height: 60px;
    /* border: 2px solid #198754; */
    margin-right: 24px;
    color: #198754;
    border-radius: 10%;
    line-height: 90px;
}

.add {
    display: inline-block;
    width: 90px;
    height: 40px;
    margin-right: 24px;
    line-height: 40px;
}

.add:hover {
    background: #198754;
    color: #fff;
    transition: all 0.2s linear;
}

.el-scrollbar__wrap {
    overflow-x: hidden;
}

.elrow {
    height: 55px;
    border-bottom: #212529;
}

.bg-purple-buttom {
    height: 48px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
}

.labels {
    display: inline-block;
    width: 90px;
    height: 38px;
    border: 1px solid #006eff;
    color: #006eff;
    text-align: center;
    line-height: 38px;
    border-radius: 10%;
    margin-left: 5px;
    text-decoration: none;
}

.labels:hover {
    background: #0063e5;
    color: white;
}

.self .el-progress-bar__inner {
    text-align: center;
}

.list3 {
    width: 100%;
    height: 60px;
    background: #f8f9fa;
}

.left3 {
    width: 80px;
    height: 60px;
    /* background: cyan; */
    /* margin-left: 24px;  */
    font-size: 18px;
    line-height: 60px;
    text-align: center;
}

.addp {
    width: 100px;
    margin-left: 6px;
}

.test {
    height: 400px;
    background-color: white;
    margin: 20px;
    padding: 20px;
    box-shadow: 3px 0px 10px rgb(201, 201, 201);
    /* background: red; */
}

.fff {
    width: 82vw;
    border-top: 1px solid #dedede;
    margin-top: 10px;
    margin-bottom: 10px;
}

.test-left {
    width: 500px;
    height: 350px;
    /* background: cyan; */
}

.text-left-top1 {
    width: 500px;
    height: 38px;
    /* background: purple; */
    display: flex;
}

.test-left-require {
    color: red;
}

.test-left-title {
    width: 60px;
    text-align: start;
    margin-right: 20px;
    font-size: 12px;
    line-height: 38px;
    color: #888888;
}

.test-left input {
    width: 367px;
    height: 36px;
    /* border: 1px solid #d6d6d6; */
}

.select {
    width: 362px;
    height: 38px;
    border: 1px solid #909399;
}

.test-left-title1 {
    display: inline-block;
    width: 33px;
    height: 36px;
    background: #e9ecef;
    color: #dc3545;
    line-height: 36px;
    border: 1px solid #909399;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.test-left-top2 {
    width: 500px;
    height: 38px;
    /* border: 1px solid #909399; */
    /* border-radius: 10px; */
    display: flex;
}

.test-left-title2 {
    display: inline-block;
    width: 74px;
    height: 36px;
    background: #e9ecef;
    border: 1px solid #909399;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    line-height: 36px;
}

.test-input {
    width: 367px;
    height: 24px;
    border: 1px solid #cfd5de;
}

.test-left-title2-1 {
    display: inline-block;
    width: 33px;
    height: 36px;
    background: #e9ecef;
    color: #dc3545;
    line-height: 36px;
    border: 1px solid #909399;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.test-left-top3 {
    width: 500px;
    height: 38px;
    /* border: 1px solid #909399; */
    /* border-radius: 10px; */
    display: flex;
}

.test-left-title3 {
    display: inline-block;
    width: 74px;
    height: 36px;
    background: #e9ecef;
    border: 1px solid #909399;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    line-height: 36px;
}

.test-left-title3-1 {
    display: inline-block;
    width: 398px;
    height: 24px;
    border: 1px solid #909399;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.test-left-top4 {
    width: 500px;
    height: 38px;
    /* border: 1px solid #909399; */
    /* border-radius: 10px; */
    display: flex;
}

.test-left-title4 {
    display: inline-block;
    width: 74px;
    height: 36px;
    background: #e9ecef;
    border: 1px solid #909399;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    line-height: 36px;
}

.test-left-title4-1 {
    display: inline-block;
    width: 398px;
    height: 24px;
    border: 1px solid #909399;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.test-left-top5 {
    width: 500px;
    height: 38px;
    display: flex;
}

.test-left-title5 {
    display: inline-block;
    width: 95px;
    height: 36px;
    background: #e9ecef;
    border: 1px solid #909399;
    line-height: 36px;
    position: absolute;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.test-left-title5-1 {
    display: inline-block;
    width: 440px;
    height: 36px;
    border: 1px solid #909399;
    padding-left: 13px;
    line-height: 32px;
}

.test-left-title5-2 {
    display: inline-block;
    width: 33px;
    height: 36px;
    background: #e9ecef;
    color: #dc3545;
    line-height: 36px;
    border: 1px solid #909399;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.submit {
    width: 500px;
    height: 38px;
    /* background: blue; */
}

.anniu {
    display: inline-block;
    width: 58px;
    height: 30px;
    background: #006eff;
    color: #fff;
    border: none;
    border-radius: 4px;
    float: left;
    margin-left: 0 !important;
}

.anniu:hover {
    background: #0b5ed7;
    transition: all 0.2s linear;
}
</style>
