<template>
    <div style="flex: 1">
        <div class="page-top" style="background-color: #1e222d; height: 50px">
            <div class="page-top-left" style="font-size: 12px; color: #c1c6c8">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                    style="font-size: 12px; color: #c1c6c8"
                    :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    style="font-size: 12px; color: #c1c6c8"
                    :to="{ path: '/vehicle' }"
                    >汽车检测</el-breadcrumb-item
                >
                </el-breadcrumb>
            </div>
            <div class="page-top-right">
                你好 , {{ $store.state.username }}
                <span @click="signOut" class="spn">退出登录</span>
            </div>
        </div>
  
        <div class="row">
            <div class="list">
                <div class="left">
                <p>汽车检测</p>
                </div>
            </div>
            <div class="table">
                <el-row>
                    <el-col :span="1"
                        ><div class="grid-content bg-purple">
                        <p class="p">#</p>
                        </div></el-col
                    >
                    <el-col :span="4"
                        ><div class="grid-content bg-purple">
                        <p class="p">识别码</p>
                        </div></el-col
                    >
                    <el-col :span="2"
                        ><div class="grid-content bg-purple">
                        <p class="p">厂商</p>
                        </div></el-col
                    >
                    <el-col :span="5"
                        ><div class="grid-content bg-purple">
                        <p class="p">车型</p>
                        </div></el-col
                    >
                    <el-col :span="2"
                        ><div class="grid-content bg-purple">
                        <p class="p">系统版本</p>
                        </div></el-col
                    >
                    <el-col :span="4"
                        ><div class="grid-content bg-purple">
                        <p class="p">送检时间</p>
                        </div></el-col
                    >
                    <el-col :span="2"
                        ><div class="grid-content bg-purple">
                        <p class="p">检测结果</p>
                        </div></el-col
                    >
                    <el-col :span="4"
                        ><div class="grid-content bg-purple">
                        <p class="p">操作</p>
                        </div></el-col
                    >
                </el-row>
  
                <el-row v-for="item in list" :key="item.id">
                  <el-col :span="1"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.id }}</p>
                    </div></el-col
                  >
                  <el-col :span="4" 
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.uuid }}</p>
                    </div></el-col
                  >
                  <el-col :span="2"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.vehicle_brand }}</p>
                    </div></el-col
                  >
                  <el-col :span="5"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.vehicle_mode }}</p>
                    </div></el-col
                  >
                  <el-col :span="2"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.os_ver }}</p>
                    </div></el-col
                  >
                  <el-col :span="4">
                    <div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.delivery_time }}</p>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content bg-purple-buttom">
                        <p class="ptit">
                            <el-button v-if="item.status==0">待检测</el-button>
                            <el-button v-else-if="item.status==1" type="primary" :loading="true">检测中</el-button>
                            <el-button v-else-if="item.status==8" type="info">存在异常</el-button>
                            <el-button v-else-if="item.status==9" type="success">通过检测</el-button>
                        </p>
                    </div>
                  </el-col>
                  <el-col :span="4"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">
                        <el-button v-if="item.status>1" type="text" @click="detail(item.id)">查看报告</el-button>
                        <el-button v-else-if="item.status==0" type="text" @click="startReport(item.id)">生成报告</el-button>
                        <template v-else>检测中</template>
                      </p>
                    </div></el-col
                  >
                </el-row>
  
            </div>
  
            <div style="width:100%;margin-bottom: 30px;">
              <el-pagination style="margin-left:auto;margin-right: auto;"
                background
                layout="prev, pager, next" :current-page="pages.current_page"
                :total="pages.page_count" @current-change="listVehicle"></el-pagination>
            </div>
  
        </div>
  
        <el-dialog :visible.sync="createVisible" title="生成报告" style="line-height:40px;">
            <el-row>
                <el-col :span="6" style="text-align: center;">*厂商</el-col>
                <el-col :span="14"><el-input v-model="saveData.vehicle_brand"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*车型：</el-col>
                <el-col :span="14"><el-input v-model="saveData.vehicle_mode"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*车架号：</el-col>
                <el-col :span="14"><el-input v-model="saveData.vehicle_vin"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*送检时间</el-col>
                <el-col :span="14" style="text-align: left;">
                  <el-date-picker v-model="saveData.delivery_picker" type="datetime" placeholder="选择日期时间"></el-date-picker>
                </el-col>
            </el-row>
            <div style="text-align: right;padding-right:200px;margin-top: 20px;"> <el-button type="primary" @click="saveApp">提交</el-button> </div>
        </el-dialog>
  
        <el-dialog :visible.sync="detailVisible" title="检测结果">
          <el-descriptions title="基本信息">
            <el-descriptions-item label="品牌">{{detailData.vehicle_brand}}</el-descriptions-item>
            <el-descriptions-item label="型号">{{detailData.vehicle_mode}}</el-descriptions-item>
            <el-descriptions-item label="车架号">{{detailData.vehicle_vin}}</el-descriptions-item>
            <el-descriptions-item label="送检时间" span="3">{{detailData.delivery_time}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="检测信息">
            <el-descriptions-item label="检测APP版本">{{detailData.app_ver}}</el-descriptions-item>
            <el-descriptions-item label="车载系统版本">{{detailData.os_ver}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="检测数据" column="1" direction="vertical" border="true">
            <el-descriptions-item v-for="(item, step) in detailData.report_data.data" label-class-name="permission-label">
              <template slot="label">{{ step }} : {{ item.title }}</template>
              <div v-for="(item2, appkey) in item.apps">
                <el-tag type="info">{{ appkey }}</el-tag>
                <table class="report_data_table">
                  <tr v-for="ident in item2">
                    <td width="100">{{ ident.key }}</td>
                    <td>{{ ident.val }}</td>
                    <td width="100">
                      <el-result  v-if="ident.err" icon="warning"></el-result>
                      <el-result  v-else icon="success"></el-result>
                    </td>
                  </tr>
                </table>
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="检测结果" column="1" style="margin-top:20px;">
            <template v-for="(item, ident) in detailData.report_data.result">
              <el-descriptions-item :label="ident">
              
                <template v-if="item.err">
                  <div style="width:100%;">
                    <el-alert v-for="txt in item.txt" type="error" :closable="false">{{ txt }}</el-alert>
                  </div>
                </template>
                <template v-else>
                  <div style="width:100%;">
                    <el-alert v-for="txt in item.txt" type="success" :closable="false">{{ txt }}</el-alert>
                  </div>
                </template>

              </el-descriptions-item>
            </template>
          </el-descriptions>
          <div style="text-align: center;">
            <el-button @click="detailVisible=false">关闭</el-button>
          </div>
        </el-dialog>
  
    </div>
  
  </template>
  <script>
  import dayjs from "dayjs";
  
  var vehicleTimer = null;
  
  export default {
    filters: {
      handleDate(val) {
        return dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    data() {
        return {
            createVisible:false,
            detailVisible:false,
            saveData:{
              id:0,
              uuid:"",
              device_id:"",
              vehicle_brand:"",
              vehicle_mode:"",
              vehicle_vin:"",
              delivery_time:"",
              delivery_picker:"",
            },
            list:[],
            pages:{current_page:1,page_count:0},
            detailData:{
              id:0,
              uuid:"",
              device_id:"",
              vehicle_brand:"",
              vehicle_mode:"",
              vehicle_vin:"",
              delivery_time:"",
              result_data:{},
              report_data:{},
            },
        }
    },
    methods:{
        signOut() {
            this.$confirm("确定要退出吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                window.sessionStorage.clear();
                this.$store.commit("SET_TOKEN", "");
                this.$store.commit("SET_USER_NAME", "");
                this.$message.success("退出成功");
                setTimeout(() => {
                    this.$router.push({
                    name: "login",
                    replace: true,
                    });
                }, 1500);
            })
            .catch((err) => {});
        },
        handleFile(e) {
            this.saveData.apk = e.target.files[0];
        },
        saveApp(){
          var errFlag = false;
          if(this.saveData.vehicle_brand==""){
            errFlag = true;
            this.$message.error('品牌不能为空');
          }
          if(this.saveData.vehicle_mode==""){
            errFlag = true;
            this.$message.error('车型不能为空');
          }
          if(this.saveData.vehicle_vin==""){
            errFlag = true;
            this.$message.error('车架号不能为空');
          }
          if( this.saveData.delivery_picker==null || this.saveData.delivery_picker==""){
            errFlag = true;
            this.$message.error('请选择时间');
          }
          if(errFlag){
            return false;
          }

          var d = new Date(this.saveData.delivery_picker);
          this.saveData.delivery_time = dayjs(d.getTime()).format("YYYY-MM-DD HH:mm:ss");

          var pre = this;

          this.$axios.post(`vehicle/start`, this.saveData).then((res, pre)=>{
            if (res.data.code == 0) {
              for(var i=0; i<this.list.length; i++){
                if(this.list[i].id== this.saveData.id){
                  this.list[i].status = 1;
                  this.list[i].vehicle_brand = this.saveData.vehicle_brand;
                  this.list[i].vehicle_mode = this.saveData.vehicle_mode;
                  this.list[i].vehicle_vin = this.saveData.vehicle_vin;
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }

            this.createVisible = false;
            this.saveData = {
                id:0,
                uuid:"",
                device_id:"",
                vehicle_brand:"",
                vehicle_mode:"",
                vehicle_vin:"",
                delivery_time:"",
              };

          }).catch((err) => { console.log(err) });
        },
        startReport(id){
          for(var i=0; i<this.list.length; i++){
            if(this.list[i].id==id){
              this.saveData.id = id;
              this.createVisible = true;
              return;
            }
          }
          this.saveData.id = 0;
          this.$message.error('内容不存在');
        },
        detail(id){
          this.$axios.post(`vehicle/detail`,{id:id}).then((res) => {
            if (res.data.code == 0) {
              //console.log(res.data);
              this.detailData.vehicle_brand = res.data.data.vehicle_brand;
              this.detailData.vehicle_mode = res.data.data.vehicle_mode;
              this.detailData.vehicle_vin = res.data.data.vehicle_vin;
              this.detailData.delivery_time = res.data.data.delivery_time;
              this.detailData.app_ver = res.data.data.app_ver;
              this.detailData.os_ver = res.data.data.os_ver;
              this.detailData.testing_data = res.data.data.testingData;
              this.detailData.report_data = res.data.data.report_data;
              this.detailVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        status(){
          var ids = [];
          for(var i=0; i<this.list.length;i++){
            ids.push(this.list[i].id);
          }
          //console.log(ids);
          this.$axios.post(`vehicle/status`,{ids:ids.join(',')}).then((res) => {
            if (res.data.code == 0) {
              
              if(res.data.data.length>0){
                for(var i=0; i<res.data.data.length; i++){
                  for(var t=0; t<this.list.length; t++){
                    if(res.data.data[i].id==this.list[t].id && res.data.data[i].status!=this.list[t].status){
                      this.list[t].status = res.data.data[i].status;
                    }
                  }
                }
              }
              //console.log(res.data.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        listVehicle(page){
          this.$axios.post(`vehicle/list`,{page:page}).then((res) => {
            if (res.data.code == 0) {
              this.list = res.data.data.list;
              this.pages = res.data.data.pages;
              //console.log(this.list);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
    },
    mounted() {
      this.listVehicle(1);
        if( !vehicleTimer ){
            vehicleTimer = setInterval(this.status, 2000);
        }
    },
  }
  </script>
  <style scoped>
  * {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
  }
  .page-top {
  height: 50px;
  /* background: pink; */
  /* border: 1px solid #6c757d; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  }
  .page-top-right {
  color: #ccc;
  }
  .spn {
  display: inline-block;
  width: 64px;
  height: 20px;
  /* background: cyan; */
  color: blue;
  }
  .row {
  height: auto;
  /* background: red; */
  position: relative;
  /* top: 60px; */
  margin: auto;
  }
  .list {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
  }
  .left {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  }
  .right {
  width: 90px;
  height: 60px;
  /* border: 2px solid #198754; */
  margin-right: 24px;
  line-height: 90px;
  }
  .add {
  display: inline-block;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  margin-right: 24px;
  line-height: 40px;
  }
  .add:hover {
  background: #198754;
  color: #fff;
  transition: all 0.2s linear;
  }
  
  .el-col {
  border-radius: 4px;
  }
  .bg-purple {
  line-height: 40px;
  text-align: left;
  border-bottom: 2px solid currentColor;
  }
  .grid-content {
  min-height: 40px;
  }
  .p {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
  }
  .bg-purple-buttom {
  height: 48px;
  border: 1px solid #ccc;
  line-height: 48px;
  text-align: left;
  border-left: none;
  border-right: none;
  }
  .ptit {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
  }
  .ptit button{font-size: 12px;}
  .label {
  width: 100%;
  height: 54px;
  /* background: red; */
  margin-top: 20px;
  }
  .pager {
  display: flex;
  justify-content: center;
  line-height: 54px;
  }
  .page-item-a {
  width: 74px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #909399;
  }
  .page-item {
  width: 148px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #ccc;
  }
  
  .list2 {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
  }
  .left2 {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;
  
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  }
  .list-center1 {
  height: 320px;
  background-color: white;
  margin: 20px;
  padding-top: 20px;
  box-shadow: 3px 0px 10px rgb(201, 201, 201);
  /* background: red; */
  }
  .list-center-all1 {
  width: 500px;
  height: 320px;
  /* background: cyan; */
  margin-top: 20px;
  }
  .list-center-top1 {
  width: 500px;
  /* height: 38px; */
  /* background: yellow; */
  display: flex;
  }
  .productname {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
  }
  .in {
  display: inline-block;
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
  }
  .list-center-center1 {
  width: 500px;
  display: flex;
  }
  .mation {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
  }
  .list-center-bottom1 {
  width: 500px;
  /* height: 38px; */
  /* background: purple; */
  display: flex;
  }
  .explain {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
  }
  .text1 {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
  }
  .texta {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
  }
  .submit {
  width: 500px;
  /* height: 38px; */
  /* background: blue; */
  }
  .anniu {
  float: left;
  display: inline-block;
  width: 58px;
  /* height: 38px; */
  color: #fff;
  border: none;
  border-radius: 4px;
  }
  .anniu:hover {
  background: #0b5ed7;
  transition: all 0.2s linear;
  }
  .test-left-title5-1 {
    display: inline-block;
    width: 440px;
    height: 36px;
    padding-left: 13px;
    line-height: 24px;
  }
  .permission-label{
    font-weight: bold !important;
  }
  .el-descriptions-item__cell .el-descriptions-item__content{
    color:#888888;
  }

  .report_data_table{
    width: 80%;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .report_data_table tr{
    border-bottom: #ccc dashed 1px;
  }
  .report_data_table .el-result{
    padding: 0 0;
  }

  /deep/.report_data_table .el-result__icon svg {
    width: 16px !important;
    height: 16px !important;
  }

  </style>
  