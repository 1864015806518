<template>
  <div class="home">
    <el-container style="display: flex" class="cont">
      <aside class="aside" :style="{ width: isCollapse ? '70px' : '200px' }">
        <div class="image">
          <img v-if="!isCollapse" src="../assets/logo-h.png" alt="" />
        </div>
        <el-menu
          :default-active="actived"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          text-color="#c1c6c8"
          active-text-color="#fff"
          :collapse="isCollapse"
        >
          <el-submenu index="1">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span class="span-a">📰概览</span>
            </template>
            <el-menu-item-group class="ones">
              <router-link
                to="/"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="1-1">
                  ·&nbsp;&nbsp;&nbsp;首页
                </el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span class="span-a">🎛 产品管理</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/productlist"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="2-1"
                  >·&nbsp;&nbsp;&nbsp;产品列表</el-menu-item
                >
              </router-link>
              <router-link
                to="/testlist"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="2-2"
                  >·&nbsp;&nbsp;&nbsp;测试列表</el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span class="span-a">⚐ 标准检验</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/anonymous"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="3-1"
                  >·&nbsp;&nbsp;&nbsp;匿名检测</el-menu-item
                >
              </router-link>
              <router-link
                to="/smallrule"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="3-2"
                  >·&nbsp;&nbsp;&nbsp;最小必要</el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <span class="span-a">⚐ 车机检验</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/vehicle"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="4-1"
                  >·&nbsp;&nbsp;&nbsp;匿名检测</el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span class="span-a">⚙ 系统管理</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/password"
                style="border-bottom: none; text-decoration: none"
              >
                <el-menu-item index="5-1"
                  >·&nbsp;&nbsp;&nbsp;修改密码</el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="" style="text-align: right;">
            <template slot="title">
              <i class="el-icon-s-fold" @click="isCollapse = !isCollapse"></i>
            </template>
          </el-submenu>
        </el-menu>
      </aside>
      <el-container style="flex: 1">
        <router-view />
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      actived: "1-1",
      routeIndex: {
        homepage: "1-1",
        productlist: "2-1",
        testlist: "2-2",
        anonymous: "3-1",
        smallrule: "3-2",
        vehicle:"4-1",
        password: "5-1",
      },
      isCollapse: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.actived = this.routeIndex[val.name];
      },
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.el-menu-item-group {
  background: #151822;
  color: #c1c6c8;
}

/deep/.el-submenu__title:hover {
  background: #262f3e;
}

/deep/.el-menu-item-group__title {
  padding: 0;
}
/deep/.el-icon-arrow-down:before {
  content: none;
}
.el-menu-vertical-demo {
  border-right: none;
  background-color: #1e222d;
}
.home {
  height: 100vh;
}
.image {
  width: 175px !important;
  height: 50px !important;
  /* border: 1px solid red; */
}
img {
  width: 100%;
  margin: 10px 10px;
}
.aside {
  height: 100vh;
  background-color: #1e222d;
  transition: width 0.5s ease;
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
/deep/.el-submenu .el-menu-item {
  height: 38px;
  line-height: 38px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}
.span-a {
  display: inline-block;
  width: 180px;
  height: 38px;
  text-align: left;
}
:deep .el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #c1c6c8 !important;
  background: #262f3e !important;
}
:deep .el-menu .el-menu-item.is-active {
  background: #006eff !important;
}

/deep/.el-submenu__title {
  height: 38px;
  line-height: 38px;
}
.el-submenu :deep .el-submenu__title:hover {
  color: #c1c6c8 !important;
  background: #262f3e !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
