import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:sessionStorage.getItem('token')||'',
    username:sessionStorage.getItem('username')||''
  },
  getters: {
  },
  mutations: {
    SET_TOKEN(state,token){
      state.token = token
      sessionStorage.setItem('token',token)
    },
    SET_USER_NAME(state,username){
      state.username = username
      sessionStorage.setItem('username',username)
    }
  },
  actions: {
  },
  modules: {
  }
})
