<template>
  <div style="flex: 1">
    <div class="page-top" style="background-color: #1e222d; height: 50px">
      <div class="page-top-left" style="font-size: 12px; color: #c1c6c8">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/productlist' }"
            >产品列表</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="page-top-right">
        你好 , {{ $store.state.username }}
        <span @click="signOut" class="spn">退出登录</span>
      </div>
    </div>

    <div class="row" v-if="!show">
      <div class="list">
        <div class="left">
          <p>项目列表</p>
        </div>
      </div>
      <div class="right">
        <button class="add" @click="addbutton()">添加产品</button>
      </div>
      <div class="table">
        <el-row>
          <el-col :span="3"
            ><div class="grid-content bg-purple">
              <p class="p">#</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple">
              <p class="p">产品名称</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple">
              <p class="p">厂商</p>
            </div></el-col
          >
          <el-col :span="9"
            ><div class="grid-content bg-purple">
              <p class="p">创建时间</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple">
              <p class="p">操作</p>
            </div></el-col
          >
        </el-row>

        <el-row v-for="item in tableObj.list" :key="item.id">
          <el-col :span="3"
            ><div class="grid-content bg-purple-buttom">
              <p class="ptit">{{ item.id }}</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple-buttom">
              <p class="ptit">{{ item.product_name }}</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple-buttom">
              <p class="ptit">{{ item.vendor }}</p>
            </div></el-col
          >
          <el-col :span="9"
            ><div class="grid-content bg-purple-buttom">
              <p class="ptit">{{ item.created_time | handleDate }}</p>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="grid-content bg-purple-buttom">
              <p class="ptit">
                <router-link :to="{ path: `./addTestlist?name=${item.id}` }"
                  >添加测试</router-link
                >
              </p>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="label">
        <ul class="pager">
          <li class="page-item-a" @click="prevousPage"><span>上一页</span></li>
          <li class="page-item-a" @click="nextPage">
            <span>下一页</span>
          </li>
          <li class="page-item">
            <span>当前第{{ page }}页/共{{ tableObj.total_page }}页</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="newadd" v-if="show">
      <div class="list2">
        <div class="left2">
          <p>添加产品</p>
        </div>
      </div>
      <div class="list-center1">
        <div class="list-center-all1">
          <div class="list-center-top1">
            <span class="productname">产品名称 : </span>
            <input v-model="product_name" type="text" class="in" />
          </div>
          <br />
          <div class="list-center-center1">
            <span class="mation">厂商信息 : </span>
            <textarea v-model="vendor" name="" class="text1"></textarea>
          </div>
          <br />
          <div class="list-center-bottom1">
            <span class="explain">产品说明 : </span>
            <textarea v-model="description" name="" class="texta"></textarea>
          </div>
          <br />
          <div class="submit">
            <button class="anniu" @click="submit()">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import testlist from "./testlist";
import dayjs from "dayjs";

export default {
  data() {
    return {
      show: false,
      product_name: "",
      vendor: "",
      description: "",
      page: this.$route.query.page ? this.$route.query.page : 1,
      size: 20,
      tableObj: {},
    };
  },
  filters: {
    handleDate(val) {
      return dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    nextPage() {
      if (this.page <= 1 || this.page >= this.tableObj.total_page) {
        console.log("no page");
        return;
      }
      location.href = "/#/productlist?page=" + (parseInt(this.page) + 1);
      window.location.reload();
    },
    prevousPage() {
      if (this.page <= 1 || this.page >= this.tableObj.total_page) {
        console.log("no page");
        return;
      }

      this.$router.push({
        path: "/productlist",
        query: {
          page: parseInt(this.page) - 1,
        },
      });
      window.location.reload();
    },
    handleClick(row) {
      console.log(row);
    },

    addbutton() {
      this.show = true;
    },
    //添加
    submit() {
      const { product_name, vendor, description } = this;
      this.$axios
        .post("product/create", {
          product_name,
          vendor,
          description,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //获取列表
    getLists() {
      this.$axios
        .post(`product/list`, {
          page: this.page,
          size: this.size,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.tableObj = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    signOut() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$store.commit("SET_TOKEN", "");
          this.$store.commit("SET_USER_NAME", "");
          this.$message.success("退出成功");
          setTimeout(() => {
            this.$router.push({
              name: "login",
              replace: true,
            });
          }, 1500);
        })
        .catch((err) => {});
    },
  },
  created() {
    this.getLists();
  },
  component() {
    testlist;
  },
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}
.page-top {
  height: 50px;
  /* background: pink; */
  /* border: 1px solid #6c757d; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.page-top-right {
  color: #ccc;
}
.spn {
  display: inline-block;
  width: 64px;
  height: 20px;
  /* background: cyan; */
  color: blue;
}
.row {
  height: auto;
  /* background: red; */
  position: relative;
  /* top: 60px; */
  margin: auto;
}
.list {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.right {
  width: 90px;
  height: 60px;
  /* border: 2px solid #198754; */
  margin-right: 24px;
  line-height: 90px;
}
.add {
  display: inline-block;
  width: 90px;
  height: 40px;
  margin-right: 24px;
  line-height: 40px;
}
.add:hover {
  background: #198754;
  color: #fff;
  transition: all 0.2s linear;
}

.el-col {
  border-radius: 4px;
}
.bg-purple {
  line-height: 40px;
  text-align: left;
  border-bottom: 2px solid currentColor;
}
.grid-content {
  min-height: 40px;
}
.p {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
}
.bg-purple-buttom {
  height: 48px;
  border: 1px solid #ccc;
  line-height: 48px;
  text-align: left;
  border-left: none;
  border-right: none;
}
.ptit {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
}
.label {
  width: 100%;
  height: 54px;
  /* background: red; */
  margin-top: 20px;
}
.pager {
  display: flex;
  justify-content: center;
  line-height: 54px;
}
.page-item-a {
  width: 74px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #909399;
}
.page-item {
  width: 148px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #ccc;
}

.list2 {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
}
.left2 {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;

  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.list-center1 {
  height: 320px;
  background-color: white;
  margin: 20px;
  padding-top: 20px;
  box-shadow: 3px 0px 10px rgb(201, 201, 201);
  /* background: red; */
}
.list-center-all1 {
  width: 500px;
  height: 320px;
  /* background: cyan; */
  margin-top: 20px;
}
.list-center-top1 {
  width: 500px;
  /* height: 38px; */
  /* background: yellow; */
  display: flex;
}
.productname {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.in {
  display: inline-block;
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.list-center-center1 {
  width: 500px;
  display: flex;
}
.mation {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.list-center-bottom1 {
  width: 500px;
  /* height: 38px; */
  /* background: purple; */
  display: flex;
}
.explain {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.text1 {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.texta {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.submit {
  width: 500px;
  /* height: 38px; */
  /* background: blue; */
}
.anniu {
  float: left;
  display: inline-block;
  width: 58px;
  /* height: 38px; */
  color: #fff;
  border: none;
  border-radius: 4px;
}
.anniu:hover {
  background: #0b5ed7;
  transition: all 0.2s linear;
}
</style>
