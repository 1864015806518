<template>
  <div class="data">
    <div class="closeBtn" @click="$emit('close')">x</div>
    <div class="title">
      <p class="title-text">移动设备上报数据大盘</p>
    </div>
    <div class="body">
      <div class="body-table" ref="ta">
        <table class="table-body" border="0">
          <tr class="table-tr">
            <th>CMDLINE</th>
          </tr>
          <tr v-for="item in cmdline" :key="item">
            <td class="table-item">{{ item }}</td>
          </tr>
        </table>
      </div>
      <div class="body-process">
        <el-progress
          color="white"
          :width="150"
          type="circle"
          :percentage="data.process"
          :format="
            (num) => {
              return num + '% 扫描中';
            }
          "
        >
        </el-progress>
        <p class="process-text">正在扫描设备，请稍后</p>
      </div>
    </div>
    <div class="footer">
      <gauge class="footer-item" name="ENGINE A" :data="data.engine1"></gauge>
      <gauge class="footer-item" name="ENGINE B" :data="data.engine2"></gauge>
      <gauge class="footer-item" name="ENGINE C" :data="data.engine3"></gauge>
      <gauge class="footer-item" name="ENGINE D" :data="data.engine4"></gauge>
    </div>
  </div>
</template>

<script>
import Gauge from "./Gauge.vue";
let time;
export default {
  name: "DataSheet",
  components: { Gauge },
  props: ["id"],
  data() {
    this.getData();
    clearInterval(time);
    time = setInterval(this.getData, 1000);
    return {
      data: {},
      cmdline: [],
    };
  },
  methods: {
    getData() {
      this.$axios.post(`version/process`, { id: this.id }).then((res) => {
        if (res.data.code == 0) {
          this.data = res.data.data;
          this.cmdline.push(this.data.cmd_line);
          var element = this.$refs.ta;
          element.scrollTop = element.scrollHeight;
          // console.log('111',this.data.cmd_line)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
<style>
p {
  margin: 0;
}

.data {
  background-color: #101129;
  font-size: 12px;
  color: #2386d7;
  position: fixed;
  left: 0;
  right: 0;
  top: 20%;
  margin: 0 auto;
  width: 800px;
  height: 420px;
  border-radius: 10px;
  z-index: 100;
}

.closeBtn {
  /* color: red; */
  position: absolute;
  right: 3px;
  top: -8px;
  font-size: 20px;
  cursor: pointer;

}
.title {
  background-image: url("../assets/title.jpg");
  height: 40px;
  background-size: 800px 40px;
}

.title-text {
  padding-top: 10px;
  color: #55bff4;
}

.body {
  height: 255px;
  display: flex;
}

.body-table {
  height: 100%;
  flex: 1;
  background-image: url("../assets/table.jpg");
  background-size: 460px 255px;
  overflow-y: scroll;
}

.table-body {
  width: 100%;
}

.table-item {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 5px;
}
/* 2386d7 */
.body-process {
  height: 100%;
  width: 336px;
  /* background-color: #2386d7; */
  background-color: #0E2349;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url("../assets/revolve.gif");
  background-size: 350px 350px;
  background-position: center;
  background-repeat: no-repeat;
}
.process-text {
  padding-top: 30px;
  display: block;
  color: white;
  flex: 0;
}

.footer {
  height: 125px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.el-progress__text {
  color: white !important;
}

svg path:first-child {
  stroke: #75b5ea;
}
</style>
