<template>
  <div class="page">
    <div class="page-top">
      <div class="page-top-left">
        <el-breadcrumb
          style="font-size: 12px; color: #c1c6c8"
          separator-class="el-icon-arrow-right"
        >
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="page-top-right">
        你好 , {{ $store.state.username }}
        <span @click="signOut" class="spn">退出登录</span>
      </div>
    </div>
    <div class="page-all">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          :title="`项目数量 :${users.total_product}`"
          name="1"
          class="hide"
        >
          <div class="one">显示所创建项目的总数量</div>
        </el-collapse-item>
        <el-collapse-item
          :title="`总测试数量 : ${users.total_version}`"
          name="2"
          class="hide"
        >
          <div class="one">显示创建的测试总数量</div>
        </el-collapse-item>
        <el-collapse-item
          :title="`已完成测试 : ${users.total_version_complete}`"
          name="3"
          class="hide"
        >
          <div class="one">显示已经完成测试并产出结果的测试数量</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "1",
      tableObj: {},
      total_prodect: "",
      total_version: "",
      total_version_complete: "",
      users: {},
    };
  },
  methods: {
    titles() {
      this.$axios({
        method: "POST",
        url: "/index/overview",
      }).then((res) => {
        if (res.data.code == 0) {
          this.tableObj = res.data.data;
          console.log(res.data.data);
          this.users = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    signOut() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$store.commit("SET_TOKEN", "");
          this.$store.commit("SET_USER_NAME", "");
          this.$message.success("退出成功");
          setTimeout(() => {
            this.$router.push({
              name: "login",
              replace: true,
            });
          }, 1000);
        })
        .catch((err) => {});
    },
  },
  created() {
    this.titles();
  },
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page-top {
  height: 50px;
  /* background: pink; */
  /* border: 1px solid #6c757d; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-top-left {
  padding: 16px;
}
.page-top-right {
  color: #ccc;
}
.spn {
  display: inline-block;
  width: 64px;
  height: 20px;
}
.spn:hover {
  cursor: pointer;
}
.page-all {
  height: 160px;
  /* background: red; */
  position: relative;
  /* top: 60px; */
  /* left: 16px; */
  padding-left: 16px;
}
.page-all :deep .el-collapse-item__header {
  font-size: 16px;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0px;
}
.one {
  text-align: left;
  line-height: 49px;
  color: #909399;
  font-size: 15px;
}
</style>
