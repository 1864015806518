<template>
    <div class="root">
        <div>
            <div class="chart" ref="chartRef"></div>
            <p>{{ name }}</p>
        </div>
        <div>
            <p>CPU: {{ data.cpu }}%</p>
            <p>MEM: {{ data.mem }}</p>
        </div>
    </div>
</template>
  
<script>
import * as Echarts from 'echarts'
export default {
    name: "gauge",
    props: {
        name: {
            type: String
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            chart: {}
        }
    },
    mounted() {
        this.chart = Echarts.init(this.$refs.chartRef)
        const option = this.getOption();
        this.chart.setOption(option)
    },
    watch: {
        data() {
            console.log("data changed");
            const option = this.getOption();
            option.series[0].data[0].value = this.data.cpu
            this.chart.setOption(option)
        }
    },
    methods: {
        getOption() {
            return {
                series: [
                    {
                        type: 'gauge',
                        radius: '100%',  //半径
                        center: ['50%', '50%'],  //边距
                        progress: {
                            show: true,
                            width: 5
                        },
                        axisLine: {
                            lineStyle: {
                                width: 5
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            distance: 0,
                            length: 4,
                            lineStyle: {
                                width: 1,
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            distance: 7, // 数值表示小文字
                            color: '#999',
                            fontSize: 6
                        },
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 2,
                            itemStyle: {
                                borderWidth: 10
                            }
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            fontSize: 10,
                            offsetCenter: [0, '70%']
                        },
                        data: [
                            {
                                value: 70
                            }
                        ]
                    }
                ]
            };
        }
    }

};
</script>
  
<style scoped>
p {
    margin: 0;
}

.chart {
    height: 80px;
    width: 80px;
    margin: 0 5px 5px 0px
}

.root {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
  