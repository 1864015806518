<template>
  <div class="newadd1">
    <div class="page-top">
      <div class="page-top-left">
        <el-breadcrumb
          style="font-size: 12px; color: #c1c6c8"
          separator-class="el-icon-arrow-right"
        >
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="page-top-right">
        你好 , {{ $store.state.username }}
        <span @click="signOut" class="spn">退出登录</span>
      </div>
    </div>

    <div class="list3">
      <div class="left3">
        <p>添加测试</p>
      </div>
    </div>
    <div class="test">
      <div class="test-left">
        <div class="text-left-top1">
          <span class="test-left-title">所属产品 : </span>
          <select
            name=""
            v-model="product_id"
            class="select"
            style="height: 36px; width: 335px"
          >
            <option v-for="item in listAll" :key="item.id" :value="item.id">
              {{ item.product_name }}
            </option>
          </select>
          <span class="test-left-title1">*</span>
        </div>
        <br />
        <div class="test-left-top2">
          <span class="test-left-title2">版本 : </span>
          <input v-model="version_name" type="text" class="test-input" />
          <span class="test-left-title2-1">*</span>
        </div>
        <br />
        <div class="test-left-top3">
          <span class="test-left-title3">日期 : </span>
          <input
            v-model="version_date"
            type="text"
            class="test-left-title3-1"
          />
        </div>
        <br />
        <div class="test-left-top4">
          <span class="test-left-title4">说明 : </span>
          <input v-model="description" type="text" class="test-left-title4-1" />
        </div>
        <br />
        <div class="test-left-top5" style="display: flex; align-items: center">
          <span class="test-left-title5"
            ><label for="file">选择文件 : </label></span
          >
          <input
            id="file"
            @change="handleFile"
            type="file"
            class="test-left-title5-1"
          />
          <span class="test-left-title5-2">*</span>
        </div>
        <br />
        <div class="submit">
          <button class="anniu" @click="sub()">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      product_id: 4,
      version_name: "",
      version_date: "",
      description: "",
      version_file: "",
      tableObj: {},
      listAll: [],
    };
  },
  watch: {
    isif(val) {
      if (val) return this.getSelectList();
    },
  },
  methods: {
    sub() {
      const {
        product_id,
        version_name,
        version_date,
        description,
        version_file,
      } = this;
      const formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("version_name", version_name);
      formData.append("version_date", version_date);
      formData.append("description", description);
      formData.append("version_file", version_file);
      this.$axios({
        method: "POST",
        url: "version/create",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    handleFile(e) {
      this.version_file = e.target.files[0];
    },
    //获取列表
    getLists() {
      return this.$axios
        .get(`version/list?page=${this.page}&size=${this.size}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.tableObj = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //获取列表状态
    getListsStatus() {
      const ids = this.tableObj.list.map((item) => item.id);
      return this.$axios
        .post(`version/status`, { version_ids: ids.join(",") })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data);
            this.tableObj.list = this.tableObj.list.map((item, index) => {
              return { ...item, ...res.data.data[index] };
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    startTest(id) {
      this.$axios.post(`version/start`, { id }).then(async (res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          await this.getLists();
          await this.getListsStatus();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    signOut() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$store.commit("SET_TOKEN", "");
          this.$store.commit("SET_USER_NAME", "");
          this.$message.success("退出成功");
          setTimeout(() => {
            this.$router.push({
              name: "login",
              replace: true,
            });
          }, 1500);
        })
        .catch((err) => {});
    },
    //获取下拉列表
    getSelectList() {
      this.$axios.post(`product/listall`).then((res) => {
        if (res.data.code == 0) {
          this.listAll = res.data.data.list;
          this.product_id = this.$route.query.name;

          console.log(this.listAll);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  async created() {
    await this.getLists();
    await this.getListsStatus();
    console.log(this.$route);

    this.getSelectList();
  },
  mounted() {},
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}
.newadd1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page-top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.page-top-right {
  color: #ccc;
}
.spn {
  display: inline-block;
  width: 64px;
  height: 20px;
  /* background: cyan; */
  color: blue;
}
.spn:hover {
  cursor: pointer;
}
.text {
  height: auto;
  /* background: red; */
  position: relative;
  /* top: 60px; */
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  min-height: 36px;
}
.p {
  margin-left: 10px;
  font-weight: bold;
}
.bg-purple {
  border: 1px solid #ccc;
  line-height: 40px;
  text-align: left;
  border-bottom: 2px solid currentColor;
}
.bg-purple-buttom {
  border: 1px solid #ccc;
  line-height: 40px;
  text-align: left;
  height: 38px;
}
.ptit {
  margin-left: 10px;
}

.newadd1 {
  padding-left: 15px;
}
.list {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.right {
  width: 92px;
  height: 60px;
  /* border: 2px solid #198754; */
  margin-right: 24px;
  color: #198754;
  border-radius: 10%;
  line-height: 60px;
}
.add {
  display: inline-block;
  width: 90px;
  height: 40px;
  border: 2px solid #198754;
  margin-right: 24px;
  color: #198754;
  border-radius: 10%;
  line-height: 40px;
}
.add:hover {
  background: #198754;
  color: #fff;
  transition: all 0.2s linear;
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.elrow {
  height: 55px;
  border-bottom: #212529;
}
.bg-purple-buttom {
  height: 55px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-evenly;
}
.labels {
  display: inline-block;
  width: 90px;
  height: 38px;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  text-align: center;
  line-height: 38px;
  border-radius: 10%;
  margin-left: 5px;
  text-decoration: none;
}
.labels:hover {
  background: #007bff;
  color: white;
}
.self .el-progress-bar__inner {
  text-align: center;
}
.list3 {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
}
.left3 {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  /* margin-left: 24px;  */
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.test {
  width: 100%;
  height: 350px;
  /* background: red; */
}
.test-left {
  width: 500px;
  height: 350px;
  /* background: cyan; */
}
.text-left-top1 {
  width: 500px;
  height: 38px;
  /* background: purple; */
  display: flex;
}
.test-left-title {
  display: inline-block;
  width: 106px;
  height: 36px;
  background: #e9ecef;
  line-height: 36px;
  border: 1px solid #909399;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.test-left input {
  height: 36px;
}
.select {
  width: 362px;
  height: 38px;
  border: 1px solid #909399;
}
.test-left-title1 {
  display: inline-block;
  width: 33px;
  height: 36px;
  background: #e9ecef;
  color: #dc3545;
  line-height: 36px;
  border: 1px solid #909399;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.test-left-top2 {
  width: 500px;
  height: 38px;
  /* border: 1px solid #909399; */
  /* border-radius: 10px; */
  display: flex;
}
.test-left-title2 {
  display: inline-block;
  width: 74px;
  height: 36px;
  background: #e9ecef;
  border: 1px solid #909399;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 36px;
}
.test-input {
  display: inline-block;
  width: 367px;
  height: 24px;
  border: 1px solid #909399;
  border-left: none;
}
.test-left-title2-1 {
  display: inline-block;
  width: 33px;
  height: 36px;
  background: #e9ecef;
  color: #dc3545;
  line-height: 36px;
  border: 1px solid #909399;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.test-left-top3 {
  width: 500px;
  height: 38px;
  /* border: 1px solid #909399; */
  /* border-radius: 10px; */
  display: flex;
}
.test-left-title3 {
  display: inline-block;
  width: 74px;
  height: 36px;
  background: #e9ecef;
  border: 1px solid #909399;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 36px;
}
.test-left-title3-1 {
  display: inline-block;
  width: 398px;
  height: 24px;
  border: 1px solid #909399;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.test-left-top4 {
  width: 500px;
  height: 38px;
  /* border: 1px solid #909399; */
  /* border-radius: 10px; */
  display: flex;
}
.test-left-title4 {
  display: inline-block;
  width: 74px;
  height: 36px;
  background: #e9ecef;
  border: 1px solid #909399;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 36px;
}
.test-left-title4-1 {
  display: inline-block;
  width: 398px;
  height: 24px;
  border: 1px solid #909399;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.test-left-top5 {
  width: 500px;
  height: 38px;
  display: flex;
}
.test-left-title5 {
  display: inline-block;
  width: 95px;
  height: 36px;
  background: #e9ecef;
  border: 1px solid #909399;
  line-height: 36px;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.test-left-title5-1 {
  display: inline-block;
  width: 440px;
  height: 36px;
  border: 1px solid #909399;
  padding-left: 13px;
  line-height: 32px;
}
.test-left-title5-2 {
  display: inline-block;
  width: 33px;
  height: 36px;
  background: #e9ecef;
  color: #dc3545;
  line-height: 36px;
  border: 1px solid #909399;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.submit {
  width: 500px;
  height: 38px;
  /* background: blue; */
}
.anniu {
  display: inline-block;
  width: 58px;
  height: 38px;
  background: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.anniu:hover {
  background: #0b5ed7;
  transition: all 0.2s linear;
}
</style>
