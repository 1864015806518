<template>
  <div>
    <div>
      <div id="big-a">
        <div class="big-b">
          <div class="img">
            <img src="../assets/logo.png" alt="" />
          </div>
          <h2 style="color: white; margin-top: 20px">
            神经元数字科技汽车安全检测平台
          </h2>
          <div class="user">
            <div class="inp">
              <span class="span">账号 : </span>
              <input type="text" class="input" v-model="loginData.username" />
            </div>
            <br />
            <div class="inp">
              <span class="span">密码 : </span>
              <input
                type="password"
                class="input"
                v-model="loginData.password"
              />
            </div>
            <br />
            <div class="password">
              <button class="ton" @click="jump()" @keyup="jumpp">登录</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginData: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    const self = this;
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e;
      if (ev.keyCode === 13) {
        self.jump();
      }
    };
  },
  methods: {
    jump() {
      this.$axios({
        method: "POST",
        url: "admin/login",
        data: {
          ...this.loginData,
        },
      }).then((res) => {
        // console.log(res.data);
        if (res.data.code == 0) {
          this.$message.success("登陆成功");
          this.$store.commit("SET_TOKEN", res.data.data.token);
          this.$store.commit("SET_USER_NAME", this.loginData.username);
          this.$router.push({
            name: "homepage",
            replace: true,
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    jumpp(e) {
      console.log(e);
    },
  },
};
</script>
<style>
* {
  margin: 0px;
  padding: 0px;
}
#big-a {
  width: 100%;
  height: 100vh;
  background: #1e222d;
  margin: 0 auto;
}
.big-b {
  /* width: 400px; */
  /* height: 100vh; */
  /* background: red; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 100px;
}
.user {
  width: 300px;
  height: 162px;
  /* background: purple; */
  margin: 30px auto;
}
.inp {
  width: 300px;
  height: 38px;
  /* border: 1px solid #ccc; */
  display: flex;
}
.span {
  display: inline-block;
  width: 74px;
  height: 38px;
  background: #e9ecef;
  text-align: center;
  line-height: 38px;
  color: #212529;
}
.input {
  display: inline-block;
  width: 227px;
  height: 38px;
}
.password {
  width: 300px;
  height: 38px;
  /* background: orange; */
  text-align: right;
}
.ton {
  display: inline-block;
  width: 58px;
  height: 38px;
  background: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.ton:hover {
  background: #0b5ed7;
  transition: all 0.2s linear;
}
</style>
