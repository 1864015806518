<template>
    <div style="flex: 1">
        <div class="page-top" style="background-color: #1e222d; height: 50px">
            <div class="page-top-left" style="font-size: 12px; color: #c1c6c8">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                    style="font-size: 12px; color: #c1c6c8"
                    :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    style="font-size: 12px; color: #c1c6c8"
                    :to="{ path: '/anonymous' }"
                    >匿名检测</el-breadcrumb-item
                >
                </el-breadcrumb>
            </div>
            <div class="page-top-right">
                你好 , {{ $store.state.username }}
                <span @click="signOut" class="spn">退出登录</span>
            </div>
        </div>

        <div class="row">
            <div class="list">
                <div class="left">
                <p>匿名检测</p>
                </div>
            </div>
            <div class="right">
                <button class="add" @click="addButton">创建匿名检测</button>
            </div>
            <div class="table">
                <el-row>
                    <el-col :span="1"
                        ><div class="grid-content bg-purple">
                        <p class="p">#</p>
                        </div></el-col
                    >
                    <el-col :span="4"
                        ><div class="grid-content bg-purple">
                        <p class="p">APP名称</p>
                        </div></el-col
                    >
                    <el-col :span="2"
                        ><div class="grid-content bg-purple">
                        <p class="p">版本</p>
                        </div></el-col
                    >
                    <el-col :span="5"
                        ><div class="grid-content bg-purple">
                        <p class="p">厂商</p>
                        </div></el-col
                    >
                    <el-col :span="2"
                        ><div class="grid-content bg-purple">
                        <p class="p">检测状态</p>
                        </div></el-col
                    >
                    <el-col :span="5"
                        ><div class="grid-content bg-purple">
                        <p class="p">创建时间</p>
                        </div></el-col
                    >
                    <el-col :span="5"
                        ><div class="grid-content bg-purple">
                        <p class="p">操作</p>
                        </div></el-col
                    >
                </el-row>

                <el-row v-for="item in list" :key="item.id">
                  <el-col :span="1"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.id }}</p>
                    </div></el-col
                  >
                  <el-col :span="4" 
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.appname }}</p>
                    </div></el-col
                  >
                  <el-col :span="2"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.version }}</p>
                    </div></el-col
                  >
                  <el-col :span="5"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.developer }}</p>
                    </div></el-col
                  >
                  <el-col :span="2"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">
                        <el-button v-if="item.status==0">待检测</el-button>
                        <el-button v-else-if="item.status==1" type="primary" :loading="true">检测中</el-button>
                        <el-button v-else-if="item.status==4" type="info">APK错误</el-button>
                        <el-button v-else-if="item.status==8" type="danger">未通过</el-button>
                        <el-button v-else-if="item.status==9" type="success">通过检测</el-button>
                      </p>
                    </div></el-col
                  >
                  <el-col :span="5"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">{{ item.created_time | handleDate }}</p>
                    </div></el-col
                  >
                  <el-col :span="5"
                    ><div class="grid-content bg-purple-buttom">
                      <p class="ptit">
                        <el-button v-if="item.status>1" type="text">已完成</el-button>
                        <el-button v-else-if="item.status==0" type="text" @click="start(item.id)">开始检测</el-button>
                        <template v-else>检测中</template>
                      </p>
                    </div></el-col
                  >
                </el-row>

            </div>

            <div style="width:100%;margin-bottom: 30px;">
              <el-pagination style="margin-left:auto;margin-right: auto;"
                background
                layout="prev, pager, next" :current-page="pages.current_page"
                :total="pages.page_count" @current-change="listAnonymous"></el-pagination>
            </div>

        </div>

        <el-dialog :visible.sync="createVisible" title="创建匿名检测" style="line-height:40px;">
            <el-row>
                <el-col :span="6" style="text-align: center;">*APP名称：</el-col>
                <el-col :span="14"><el-input v-model="saveData.appname"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*版本：</el-col>
                <el-col :span="14"><el-input v-model="saveData.version"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*厂商：</el-col>
                <el-col :span="14"><el-input v-model="saveData.developer"></el-input></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">*APK文件：</el-col>
                <el-col :span="14"><input id="file" @change="handleFile" type="file" class="test-left-title5-1" /></el-col>
            </el-row>
            <el-row>
                <el-col :span="6" style="text-align: center;">描述：</el-col>
                <el-col :span="14"><el-input
                    v-model="saveData.description"
                    maxlength="200"
                    placeholder="Please input"
                    show-word-limit
                    type="textarea"
                /></el-col>
            </el-row>
        
            <div style="text-align: right;padding-right:200px;margin-top: 20px;"> <el-button type="primary" @click="saveApp">提交</el-button> </div>
        </el-dialog>

    </div>

</template>
<script>
import dayjs from "dayjs";

var anonymousTimer = null;

export default {
    filters: {
      handleDate(val) {
        return dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    data() {
        return {
            createVisible:false,
            saveData:{
                appname:"",
                version:"",
                developer:"",
                describe:"",
                apk:"",
            },
            list:[],
            pages:{current_page:1,page_count:0}
        }
    },
    methods:{
        signOut() {
            this.$confirm("确定要退出吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                window.sessionStorage.clear();
                this.$store.commit("SET_TOKEN", "");
                this.$store.commit("SET_USER_NAME", "");
                this.$message.success("退出成功");
                setTimeout(() => {
                    this.$router.push({
                    name: "login",
                    replace: true,
                    });
                }, 1500);
            })
            .catch((err) => {});
        },
        handleFile(e) {
            this.saveData.apk = e.target.files[0];
        },
        addButton(){
            this.createVisible=true;
        },
        saveApp(){

          const formData = new FormData();
          formData.append("appname", this.saveData.appname);
          formData.append("version", this.saveData.version);
          formData.append("developer", this.saveData.developer);
          formData.append("description", this.saveData.description);
          formData.append("apk", this.saveData.apk);

          var pre = this;

          this.$axios({
              method: "POST",
              url: "anonymous/create",
              headers: {
                  "content-type": "multipart/form-data",
              },
              data: formData,
          }).then((res, pre) => {
              if (res.data.code == 0) {
                  this.$message.success(res.data.msg);
                  setTimeout(() => {
                            window.location.reload();
                        }, 1500);
              } else {
                  this.$message.error(res.data.msg);
              }
              pre.createVisible=false;
              pre.saveData = {appname:"",version:"",developer:"",describe:"",apk:""};
          }).catch((err) => { console.log(err) });
        },
        start(id){
          this.$axios.post(`anonymous/start`,{id:id}).then((res) => {
            if (res.data.code == 0) {
              for(var i=0; i<this.list.length; i++){
                if(this.list[i].id==id){
                  this.list[i].status = 1;
                }
              }
              //console.log(res.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        detail(id){
          this.$axios.post(`anonymous/detail`,{id:id}).then((res) => {
            if (res.data.code == 0) {
              //console.log(res.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        status(){
          var ids = [];
          for(var i=0; i<this.list.length;i++){
            ids.push(this.list[i].id);
          }
          //console.log(ids);
          this.$axios.post(`anonymous/status`,{ids:ids.join(',')}).then((res) => {
            if (res.data.code == 0) {
              
              if(res.data.data.length>0){
                for(var i=0; i<res.data.data.length; i++){
                  for(var t=0; t<this.list.length; t++){
                    if(res.data.data[i].id==this.list[t].id && res.data.data[i].status!=this.list[t].status){
                      this.list[t].status = res.data.data[i].status;
                    }
                  }
                }
              }
              
              //console.log(res.data.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        listAnonymous(page){
          this.$axios.post(`anonymous/list`,{page:page}).then((res) => {
            if (res.data.code == 0) {
              this.list = res.data.data.list;
              this.pages = res.data.data.pages;
              //console.log(this.list);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
    },
    mounted() {
      this.listAnonymous(1);
      console.log('anony mounted');
      if( !anonymousTimer ){
        anonymousTimer = setInterval(this.status, 2000);
        console.log('ano timer');
      }
    },
}
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}
.page-top {
  height: 50px;
  /* background: pink; */
  /* border: 1px solid #6c757d; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.page-top-right {
  color: #ccc;
}
.spn {
  display: inline-block;
  width: 64px;
  height: 20px;
  /* background: cyan; */
  color: blue;
}
.row {
  height: auto;
  /* background: red; */
  position: relative;
  /* top: 60px; */
  margin: auto;
}
.list {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.right {
  width: 90px;
  height: 60px;
  /* border: 2px solid #198754; */
  margin-right: 24px;
  line-height: 90px;
}
.add {
  display: inline-block;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  margin-right: 24px;
  line-height: 40px;
}
.add:hover {
  background: #198754;
  color: #fff;
  transition: all 0.2s linear;
}

.el-col {
  border-radius: 4px;
}
.bg-purple {
  line-height: 40px;
  text-align: left;
  border-bottom: 2px solid currentColor;
}
.grid-content {
  min-height: 40px;
}
.p {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
}
.bg-purple-buttom {
  height: 48px;
  border: 1px solid #ccc;
  line-height: 48px;
  text-align: left;
  border-left: none;
  border-right: none;
}
.ptit {
  font-size: 12px;
  color: #888888;
  margin-left: 10px;
  font-weight: 700;
}
.ptit button{font-size: 12px;}
.label {
  width: 100%;
  height: 54px;
  /* background: red; */
  margin-top: 20px;
}
.pager {
  display: flex;
  justify-content: center;
  line-height: 54px;
}
.page-item-a {
  width: 74px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #909399;
}
.page-item {
  width: 148px;
  height: 38px;
  border: 1px solid #ccc;
  line-height: 38px;
  color: #ccc;
}

.list2 {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
}
.left2 {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  margin-left: 24px;

  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.list-center1 {
  height: 320px;
  background-color: white;
  margin: 20px;
  padding-top: 20px;
  box-shadow: 3px 0px 10px rgb(201, 201, 201);
  /* background: red; */
}
.list-center-all1 {
  width: 500px;
  height: 320px;
  /* background: cyan; */
  margin-top: 20px;
}
.list-center-top1 {
  width: 500px;
  /* height: 38px; */
  /* background: yellow; */
  display: flex;
}
.productname {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.in {
  display: inline-block;
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.list-center-center1 {
  width: 500px;
  display: flex;
}
.mation {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.list-center-bottom1 {
  width: 500px;
  /* height: 38px; */
  /* background: purple; */
  display: flex;
}
.explain {
  display: inline-block;
  width: 90px;
  /* height: 38px; */
  color: #888888;
  font-size: 12px;
  line-height: 38px;
}
.text1 {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.texta {
  width: 392px;
  height: 36px;
  border: 1px solid #cfd5de;
}
.submit {
  width: 500px;
  /* height: 38px; */
  /* background: blue; */
}
.anniu {
  float: left;
  display: inline-block;
  width: 58px;
  /* height: 38px; */
  color: #fff;
  border: none;
  border-radius: 4px;
}
.anniu:hover {
  background: #0b5ed7;
  transition: all 0.2s linear;
}
.test-left-title5-1 {
    display: inline-block;
    width: 440px;
    height: 36px;
    padding-left: 13px;
    line-height: 24px;
}
</style>
