import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Homepage from '../views/admin/homepage.vue'
import Productlist from '../views/admin/productlist.vue'
import Testlist from '../views/admin/testlist.vue'
import Password from '../views/admin/password.vue'
import Login from '../views/login.vue'
import store from '../store/index'
import AddTestlist from '../views/admin/addTestlist.vue'
import SmallRule from '../views/admin/SmallRule.vue'
import Anonymous from '../views/admin/Anonymous.vue'
import Vehicle from '../views/admin/Vehicle.vue'


Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'login',
    component: Login

  },
  {
    path: "/",
    name: "homeView",
    component: HomeView,
    children: [{
        // 首页
        path: "",
        name: "homepage",
        component: Homepage
      },
      {
        // 产品列表
        path: "/productlist",
        name: "productlist",
        component: Productlist
      },
      {
        // 测试列表
        path: "/testlist",
        name: "testlist",
        component: Testlist,

      },
      {
        // 修改密码
        path: "/password",
        name: "password",
        component: Password
      },
      {

        path: "/addTestlist",
        name: "addTestlist",
        component: AddTestlist

      },
      {

        path: "/smallrule",
        name: "smallrule",
        component: SmallRule

      },
      {

        path: "/anonymous",
        name: "anonymous",
        component: Anonymous

      },
      {

        path: "/vehicle",
        name: "vehicle",
        component: Vehicle

      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, form, next) => {
  if (to.name != 'login') {
    if (!store.state.token) next({
      name: 'login',
      replace: true
    })
    else next()
    return;
  }
  next()
})

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

export default router