<template>
  <div style="flex: 1">
    <div class="page-top">
      <div class="page-top-left">
        <el-breadcrumb
          style="font-size: 12px; color: #c1c6c8"
          separator-class="el-icon-arrow-right"
        >
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
            style="font-size: 12px; color: #c1c6c8"
            :to="{ path: '/password' }"
            >修改密码</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="page-top-right">
        你好 , {{ $store.state.username }}
        <span @click="signOut" class="spn">退出登录</span>
      </div>
    </div>
    <div class="list3">
      <div class="left3">
        <p class="addp">修改密码</p>
      </div>
    </div>

    <div class="pass">
      <div class="center">
        <el-form
          ref="formObj"
          label-width="120px"
          :model="formObj"
          :rules="formObjRules"
        >
          <el-form-item label="输入旧密码" prop="oldPassword">
            <el-input v-model="formObj.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="输入新密码" prop="newPasswordOne">
            <el-input v-model="formObj.newPasswordOne"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="newPasswordTwo">
            <el-input v-model="formObj.newPasswordTwo"></el-input>
          </el-form-item>
          <button class="anniu" @click="resetPassword">修改</button>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formObj.newPasswordTwo !== "") {
          this.$refs.formObj.validateField("newPasswordTwo");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formObj.newPasswordOne) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      formObj: {
        oldPassword: "",
        newPasswordOne: "",
        newPasswordTwo: "",
      },
      formObjRules: {
        oldPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 100,
            message: "密码不能小于6位",
            trigger: ["blur", "change"],
          },
        ],
        newPasswordOne: [
          {
            required: true,
            message: "请输入新密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 100,
            message: "密码不能小于6位",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
        ],
        newPasswordTwo: [
          {
            required: true,
            message: "请确认新密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 100,
            message: "密码不能小于6位",
            trigger: ["blur", "change"],
          },
          { validator: validatePass2, trigger: ["blur", "change"] },
        ],
      },
    };
  },
  methods: {
    resetPassword() {
      this.$refs.formObj.validate((valid) => {
        if (valid) {
          this.$confirm("确定要修改吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$axios({
                method: "POST",
                url: "account/password",
                data: {
                  password_old: this.formObj.oldPassword, //必须
                  password_new: this.formObj.newPasswordOne, //必须
                },
              }).then((res) => {
                if (res.data.code == 0) {
                  this.$message.success(res.data.msg);
                  sessionStorage.clear();
                  this.$store.commit("SET_TOKEN", "");
                  this.$store.commit("SET_USER_NAME", "");
                  this.timer = setTimeout(() => {
                    this.$router.push({
                      name: "login",
                      replace: true,
                    });
                  }, 1500);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    signOut() {
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$store.commit("SET_TOKEN", "");
          this.$store.commit("SET_USER_NAME", "");
          this.$message.success("退出成功");
          setTimeout(() => {
            this.$router.push({
              name: "login",
              replace: true,
            });
          }, 15200);
        })
        .catch((err) => {});
    },
  },
  beforeDestroy() {
    window.clearTimeout(this.timer);
  },
};
</script>
<style scoped>
.page-top {
  height: 50px;
  /* background: pink; */
  /* border: 1px solid #6c757d; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.page-top-right {
  color: #ccc;
}
.spn {
  display: inline-block;
  width: 64px;
  height: 20px;
  /* background: cyan; */
  color: blue;
}

.list3 {
  width: 100%;
  height: 60px;
  background: #f8f9fa;
}
.left3 {
  width: 80px;
  height: 60px;
  /* background: cyan; */
  /* margin-left: 24px;  */
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
.addp {
  width: 100px;
  margin-left: 6px;
  font-size: 16px;
  color: #000000;
  font-weight: 700;
}

.pass {
  height: 280px;
  position: relative;
  top: 20px;
  background-color: white;
  margin: 0px 20px 20px 20px;
  padding-top: 20px;
  box-shadow: 3px 0px 10px rgb(201, 201, 201);
}
.center {
  width: 350px;
  height: 250px;
  margin-left: -6px;
}

.el-form {
  margin: 0px;
}

.pass-top {
  width: 350px;
  height: 40px;
  /* background: pink; */
  display: flex;
}
.spann {
  display: inline-block;
  width: 122px;
  height: 40px;
  background: #e9ecef;
  color: #212529;
  line-height: 40px;
  text-align: center;
}

.anniu {
  float: left;
  display: inline-block;
  width: 58px;
  /* height: 38px; */
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-left: 30px !important;
}

input {
  width: 229px;
  height: 26px;
  padding: 6px 12px;
  border: 1px solid #ced4da;
}
.pass-center {
  width: 350px;
  height: 40px;
  /* background: pink; */
  display: flex;
}
.pass-buttom {
  width: 350px;
  height: 40px;
  /* background: pink; */
  display: flex;
}
.button {
  width: 350px;
  height: 38px;
  /* background: red ; */
}
.butt {
  width: 58px;
  height: 38px;
  background: #0d6ef0;
  color: #fff;
  border-radius: 10%;
  border: none;
  float: right;
  line-height: 38px;
  font-size: 16px;
}
/deep/.el-form-item__label{
  color: #888888;
  font-size: 12px;
}

/deep/.el-input__inner{
  height: 36px;
}
</style>
